import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CvrService,
  DavaAddressInfo,
  DavaService,
  DmrResponse,
  HelvetiaAcceptConditions,
  HelvetiaPriceRequest,
  HelvetiaService,
  HelvetiaVariant,
  LibUtilityService,
  applyForHelvetiaInsurancePayload,
  helvetiaCustomerInfo,
} from 'projects/shared/src/public-api';
import { ResetPriceCalculator } from '../services/reset-price-calculator.service';

@Component({
  selector: 'app-helvetia-price-calculator',
  templateUrl: './helvetia-price-calculator.component.html',
  styleUrls: ['./helvetia-price-calculator.component.scss'],
})
export class HelvetiaPriceCalculatorComponent {
  @Output() helvetiaVariantsChange = new EventEmitter<HelvetiaVariant[]>();
  @Output() pickHelvetiaEvent = new EventEmitter();
  @Output() nextStepEvent = new EventEmitter();
  @Output() togglePriceIntervalEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();
  @Output() setYearlyMonthlyPriceEvent = new EventEmitter<number>();
  @Output() setYearlyPriceEvent = new EventEmitter<number>();
  @Output() setSelectedHelvetiaVariantEvent =
    new EventEmitter<HelvetiaVariant>();

  @Input() activeStep: number = 0;
  @Input() showHelvetiaInfo: boolean = false;
  @Input() helvetiaVariants: HelvetiaVariant[] = [];
  @Input() helvetiaMonthlyPrice: number = 0;
  @Input() helvetiaYearlyPrice: number = 0;
  @Input() allHaveToggleChargingStandAllowed: boolean = false;
  @Input() allHaveTogglePricetag: boolean = false;
  @Input() showYearlyPrice: boolean = false;
  @Input() userId: number = 0;
  @Input() errorIngetInsurancePrices: boolean = false;
  @Input() errorInHelvetiaPrice: boolean = false;
  @Input() customerAge: number | null = null;
  @Input() zipCode: number | null = null;
  @Input() carOwnershipType: string = '';
  @Input() vehicleFromDmr: DmrResponse = {} as DmrResponse;
  @Input() selectedHelvetiaVariant: HelvetiaVariant = {} as HelvetiaVariant;
  @Input() selectedPrice: { name: string; value: number } = {
    name: '',
    value: 0,
  };
  @Input() selectedDealer: { name: string; value: number } = {
    name: '',
    value: 0,
  };

  errorInCvrNumber: boolean = false;
  errorInExtraUserCompanyName: boolean = false;
  errorInExtraUserCvrNumber: boolean = false;

  loadinginsuranceInfo: boolean = false;
  cvrLoading: boolean = false;
  extraUserCvrLoading: boolean = false;
  applyForInsuranceLoading: boolean = false;
  annualMileagePriceUpdate: boolean = false;
  showAddressOption: boolean = false;
  showExtraUserAddressOption: boolean = false;
  requireAuth: boolean = false;

  parkingCoverage: boolean = false;
  roadsideAssistance: boolean = false;
  glassCover: boolean = false;
  chargingStand: boolean = false;

  extraUserAge: number = 0;
  helvetiaCustomerInfo: helvetiaCustomerInfo = {
    showAsBusinessCustomer: false,
    cprNumber: '',
    cvrNumber: '',
    firstname: '',
    lastname: '',
    companyName: '',
    selectedAddressInfo: {} as DavaAddressInfo,
    email: '',
    phoneNumber: '',
    extraUserType: '',
    extraUserCvrNumber: '',
    extraUserCompanyName: '',
    extraUserCprNumber: '',
    extraUserFirstname: '',
    extraUserLastname: '',
    extraUserSelectedAddressInfo: {} as DavaAddressInfo,
  };

  selectedNumberOfDamages: { name: string; value: number } = {
    name: '',
    value: -1,
  };
  selectedAnnualMileage: { name: string; value: number } = {
    name: '',
    value: -1,
  };

  helvetiaAcceptConditions: HelvetiaAcceptConditions = {
    acceptedTradingConditions: false,
    acceptedInfoIsCorrect: false,
    acceptedConsent: false,
    acceptedAsgMarketing: false,
    acceptedChargingStandConsultant: false,
    acceptedChargingStandCustomer: false,
  };

  constructor(
    private helvetiaService: HelvetiaService,
    private libUtilityService: LibUtilityService,
    private resetPriceCalculator: ResetPriceCalculator,
    private davaService: DavaService,
    private cvrService: CvrService
  ) {
    this.resetPriceCalculator.resetPriceCalculatorValues.subscribe(() => {
      this.resetHelvetiaInfo();
    });
  }

  reset() {
    this.resetEvent.emit();
  }

  resetHelvetiaInfo() {
    this.annualMileagePriceUpdate = false;
    this.helvetiaCustomerInfo = {
      showAsBusinessCustomer: false,
      cprNumber: '',
      cvrNumber: '',
      firstname: '',
      lastname: '',
      companyName: '',
      selectedAddressInfo: {} as DavaAddressInfo,
      email: '',
      phoneNumber: '',
      extraUserType: '',
      extraUserCvrNumber: '',
      extraUserCompanyName: '',
      extraUserCprNumber: '',
      extraUserFirstname: '',
      extraUserLastname: '',
      extraUserSelectedAddressInfo: {} as DavaAddressInfo,
    };

    this.helvetiaAcceptConditions = {
      acceptedTradingConditions: false,
      acceptedInfoIsCorrect: false,
      acceptedConsent: false,
      acceptedAsgMarketing: false,
      acceptedChargingStandConsultant: false,
      acceptedChargingStandCustomer: false,
    };

    this.parkingCoverage = false;
    this.roadsideAssistance = false;
    this.glassCover = false;
    this.chargingStand = false;

    this.selectedNumberOfDamages = { name: '', value: -1 };
    this.selectedAnnualMileage = { name: '', value: -1 };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedHelvetiaVariant']) {
      this.setYearlyAndMonthlyPrice();
    }
  }

  pickHelvetia(goToNextStep: boolean) {
    this.pickHelvetiaEvent.emit(goToNextStep);
  }

  setYearlyAndMonthlyPrice() {
    this.helvetiaMonthlyPrice =
      this.libUtilityService.getHelvetiaPrice(
        this.selectedHelvetiaVariant.coverages,
        'monthly'
      ) +
      this.libUtilityService.getAddonsPrices(
        this.parkingCoverage,
        this.roadsideAssistance,
        this.glassCover,
        this.selectedHelvetiaVariant.coverages,
        false
      );

    this.helvetiaYearlyPrice =
      this.libUtilityService.getHelvetiaPrice(
        this.selectedHelvetiaVariant.coverages,
        'yearly'
      ) +
      this.libUtilityService.getAddonsPrices(
        this.parkingCoverage,
        this.roadsideAssistance,
        this.glassCover,
        this.selectedHelvetiaVariant.coverages,
        true
      );

    this.setYearlyMonthlyPriceEvent.emit(this.helvetiaMonthlyPrice);
    this.setYearlyPriceEvent.emit(this.helvetiaYearlyPrice);
  }

  setSelectedHelvetiaVariant(helvetiaVariant: HelvetiaVariant) {
    this.selectedHelvetiaVariant = helvetiaVariant;
    this.setSelectedHelvetiaVariantEvent.emit(this.selectedHelvetiaVariant);
  }

  setGlassCover(glassCover: boolean) {
    this.glassCover = glassCover;
    this.setYearlyAndMonthlyPrice();
  }

  setParkingCoverage(parkingCoverage: boolean) {
    this.parkingCoverage = parkingCoverage;
    this.setYearlyAndMonthlyPrice();
  }

  setRoadsideAssistance(roadsideAssistance: boolean) {
    this.roadsideAssistance = roadsideAssistance;
    this.setYearlyAndMonthlyPrice();
  }

  setChargingStand(chargingStand: boolean) {
    this.chargingStand = chargingStand;
  }

  nextStep() {
    this.nextStepEvent.emit();
  }

  togglePriceInterval() {
    this.togglePriceIntervalEvent.emit();
  }

  setNewAge(age: number) {
    this.customerAge = age;
    this.recalculateHelvetiaPrices();
  }

  setNewExtraUserAge(age: number) {
    this.extraUserAge = age;
    this.recalculateHelvetiaPrices();
  }

  recalculateHelvetiaPrices() {
    if (this.customerAge && this.zipCode) {
      let vehicleClaims = this.selectedNumberOfDamages.value;
      if (vehicleClaims === -1) {
        vehicleClaims = 0;
      }

      // so if you enter customer info - and get 25% on the price since you choose bussins + no primary diver
      // and go back and enter a diffent damages
      // then the price is recalculated but iwht diffent values - so its cheaper
      // then you go back to the customer info - and since you allready enter the values you can just go to the "apply" step
      // What to do about that...

      // If extraUserType is not set - set it to private
      const customerIdType = this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? 'business'
        : 'private';

      // if the you choosen private user OR  business user, with primary dirver isPrivatePrimaryDriver  is ture
      let isPrivatePrimaryDriver = false;

      if (
        this.helvetiaCustomerInfo.extraUserType == '' ||
        this.helvetiaCustomerInfo.extraUserType == 'private' ||
        !this.helvetiaCustomerInfo.showAsBusinessCustomer
      ) {
        isPrivatePrimaryDriver = true;
      }

      //
      let postCode = this.zipCode;
      if (
        this.helvetiaCustomerInfo.extraUserSelectedAddressInfo &&
        this.helvetiaCustomerInfo.extraUserType == 'private' &&
        this.helvetiaCustomerInfo.showAsBusinessCustomer
      ) {
        postCode =
          +this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse
            .postnr;
      }

      const secondDriverAge = this.extraUserAge == 0 ? null : this.extraUserAge;

      const insuranceInfo: HelvetiaPriceRequest = {
        customerType: customerIdType, // private/business
        driverAge: this.customerAge,
        secondDriverAge: secondDriverAge,
        financingType: this.carOwnershipType,
        postCode: postCode.toString(),
        vehiclePrice: this.selectedPrice.value,
        vehicleHorsepower: this.vehicleFromDmr.horsepower,
        vehicleExpectedYearlyMileage: this.selectedAnnualMileage.value,
        vehicleClaims: vehicleClaims,
        isPrivatePrimaryDriver: isPrivatePrimaryDriver,
      };

      this.loadinginsuranceInfo = true;
      this.helvetiaService.getInsurancePrices(insuranceInfo).subscribe({
        next: (result) => {
          this.helvetiaVariants = result.prices;

          this.helvetiaVariantsChange.emit(this.helvetiaVariants);

          // Find the corresponding object in the array based on variant
          const selectedVariant = result.prices.find(
            (v: any) => v.variant === this.selectedHelvetiaVariant.variant
          );

          if (selectedVariant) {
            this.selectedHelvetiaVariant = selectedVariant;
          }

          this.setYearlyAndMonthlyPrice();

          console;

          this.loadinginsuranceInfo = false;
          this.annualMileagePriceUpdate = true;
        },
        error: (error) => {
          this.loadinginsuranceInfo = false;
          this.libUtilityService.notification(
            'error',
            'Fejl',
            'Der er sket en fejl - Kontakt os på support@asgcare.dk'
          );
          return;
        },
      });
    }
  }

  /**
   * Apply for Helvetia insurance using the provided customer and vehicle information.
   * Constructs the payload and sends it to the Helvetia service.
   * @returns void
   */
  applyForHelvetia(): void {
    // Determine customer ID type and number based on customer type
    const customerIdType = this.helvetiaCustomerInfo.showAsBusinessCustomer
      ? 'CVR'
      : 'CPR';
    const customerIdNumber = this.helvetiaCustomerInfo.showAsBusinessCustomer
      ? this.helvetiaCustomerInfo.cvrNumber
      : this.helvetiaCustomerInfo.cprNumber;

    // Set customer name or company name based on customer type
    const customerFirstName = this.helvetiaCustomerInfo.showAsBusinessCustomer
      ? null
      : this.helvetiaCustomerInfo.firstname;
    const customerLastName = this.helvetiaCustomerInfo.showAsBusinessCustomer
      ? null
      : this.helvetiaCustomerInfo.lastname;
    const customerCompanyName = this.helvetiaCustomerInfo.showAsBusinessCustomer
      ? this.helvetiaCustomerInfo.companyName
      : null;

    // Toggle options for charging stand and additional coverages
    const toggleChargingStand = this.chargingStand ? 1 : 0;
    const addonGlassCover = this.glassCover ? 1 : 0;
    const addonParkingDamage = this.parkingCoverage ? 1 : 0;
    const addonRoadsideAssist = this.roadsideAssistance ? 1 : 0;

    // Consent selections from the user
    const consentTerms = this.helvetiaAcceptConditions.acceptedTradingConditions
      ? 1
      : 0;
    const consentValidData = this.helvetiaAcceptConditions.acceptedInfoIsCorrect
      ? 1
      : 0;
    const consentCancelCurrentInsurance = this.helvetiaAcceptConditions
      .acceptedConsent
      ? 1
      : 0;
    const consentAsgMarketing = this.helvetiaAcceptConditions
      .acceptedAsgMarketing
      ? 1
      : 0;
    const consentChargingStandCustomer = this.helvetiaAcceptConditions
      .acceptedChargingStandCustomer
      ? 1
      : 0;
    const consentChargingStandConsultant = this.helvetiaAcceptConditions
      .acceptedChargingStandConsultant
      ? 1
      : 0;

    // Monthly payment calculation
    const calculatorMonthlyPayment = this.helvetiaMonthlyPrice;

    // Initialize primary driver information
    let primaryDriverFirstName = null;
    let primaryDriverLastName = null;
    let primaryDriverIdNumber = null;
    let primaryDriverCompanyName = null;

    // Set primary driver details based on customer type
    if (
      this.helvetiaCustomerInfo.extraUserType == 'private' &&
      this.helvetiaCustomerInfo.showAsBusinessCustomer
    ) {
      primaryDriverFirstName =
        this.helvetiaCustomerInfo.extraUserFirstname.trim();
      primaryDriverLastName =
        this.helvetiaCustomerInfo.extraUserLastname.trim();
      primaryDriverIdNumber =
        this.helvetiaCustomerInfo.extraUserCprNumber.trim();
    }

    // Initialize secondary driver information
    let secondDriverFirstName = null;
    let secondDriverLastName = null;
    let secondDriverIdNumber = null;

    // Set secondary driver details if the customer is not a business customer
    if (!this.helvetiaCustomerInfo.showAsBusinessCustomer) {
      secondDriverFirstName =
        this.helvetiaCustomerInfo.extraUserFirstname.trim();
      secondDriverLastName = this.helvetiaCustomerInfo.extraUserLastname.trim();
      secondDriverIdNumber =
        this.helvetiaCustomerInfo.extraUserCprNumber.trim();
    }

    // Set primary driver details for business customers
    if (
      this.helvetiaCustomerInfo.extraUserType == 'business' &&
      this.helvetiaCustomerInfo.showAsBusinessCustomer
    ) {
      primaryDriverCompanyName =
        this.helvetiaCustomerInfo.extraUserCompanyName.trim();
      primaryDriverLastName =
        this.helvetiaCustomerInfo.extraUserLastname.trim();
      primaryDriverIdNumber =
        this.helvetiaCustomerInfo.extraUserCvrNumber.trim();
    }

    // Set primary driver address details if applicable
    const primaryDriverStreet =
      this.helvetiaCustomerInfo.extraUserType == 'none' ||
      !this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? null
        : this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse
            .vejnavn;
    const primaryDriverBuildingNumber =
      this.helvetiaCustomerInfo.extraUserType == 'none' ||
      !this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? null
        : this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse.husnr;
    const primaryDriverFloor =
      this.helvetiaCustomerInfo.extraUserType == 'none' ||
      !this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? null
        : this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse.etage;
    const primaryDriverDoor =
      this.helvetiaCustomerInfo.extraUserType == 'none' ||
      !this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? null
        : this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse.dør;
    const primaryDriverPostCode =
      this.helvetiaCustomerInfo.extraUserType == 'none' ||
      !this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? null
        : this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse.postnr;
    const primaryDriverCity =
      this.helvetiaCustomerInfo.extraUserType == 'none' ||
      !this.helvetiaCustomerInfo.showAsBusinessCustomer
        ? null
        : this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.adresse
            .postnrnavn;

    // Set existing insurance company name if available
    const existingInsuranceCompanyName =
      this.vehicleFromDmr.currentInsuranceCompany || null;

    const vehicleExistingInsuranceStatus =
      this.vehicleFromDmr.currentInsuranceStatus || null;

    // Construct the payload for applying for Helvetia insurance
    const applyForInsurancePayload: applyForHelvetiaInsurancePayload = {
      calculatorMonthlyPayment: calculatorMonthlyPayment,
      calculatorPostcode: `${this.zipCode}`,
      customerAge: this.customerAge,
      vehicleVin: this.vehicleFromDmr.vin,
      vehicleHorsepower: this.vehicleFromDmr.horsepower,
      vehicleMake: this.vehicleFromDmr.make,
      vehicleModel: this.vehicleFromDmr.model,
      vehicleVariant: this.vehicleFromDmr.variant,
      vehicleYear: this.vehicleFromDmr.year,
      vehicleFuelType: this.vehicleFromDmr.fuelType,
      vehicleCode: this.vehicleFromDmr.code,
      vehiclePrice: this.selectedPrice.value,
      vehicleOwnership: this.carOwnershipType,
      vehicleOwnWeight: this.vehicleFromDmr.ownWeight,
      vehicleTotalWeight: this.vehicleFromDmr.totalWeight,
      vehicleBodyType: this.vehicleFromDmr.bodyType,
      vehicleFirstRegistrationDate: this.vehicleFromDmr.firstRegistrationDate,
      vehicleMileage: this.vehicleFromDmr.mileage * 1000,
      customerIdType: customerIdType,
      customerIdNumber: customerIdNumber,
      customerFirstName: customerFirstName,
      customerLastName: customerLastName,
      customerCompanyName: customerCompanyName,
      customerEmail: this.helvetiaCustomerInfo.email,
      customerPhone: `${this.helvetiaCustomerInfo.phoneNumber}`,
      customerStreet:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.vejnavn,
      customerStreetCode:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.vejkode,
      customerBuildingNumber:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.husnr,
      customerFloor:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.etage,
      customerDoor: this.helvetiaCustomerInfo.selectedAddressInfo.adresse.dør,
      customerAdditionalCity:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.supplerendebynavn,
      customerPostCode:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.postnr,
      customerCity:
        this.helvetiaCustomerInfo.selectedAddressInfo.adresse.postnrnavn,
      primaryDriverFirstName: primaryDriverFirstName,
      primaryDriverLastName: primaryDriverLastName,
      primaryDriverCompanyName: primaryDriverCompanyName,
      primaryDriverIdNumber: primaryDriverIdNumber,
      primaryDriverStreet: primaryDriverStreet,
      primaryDriverBuildingNumber: primaryDriverBuildingNumber,
      primaryDriverFloor: primaryDriverFloor,
      primaryDriverDoor: primaryDriverDoor,
      primaryDriverPostCode: primaryDriverPostCode,
      primaryDriverCity: primaryDriverCity,
      secondDriverFirstName: secondDriverFirstName,
      secondDriverLastName: secondDriverLastName,
      secondDriverIdNumber: secondDriverIdNumber,
      toggleChargingStand: toggleChargingStand,
      addonGlassCover: addonGlassCover,
      addonParkingDamage: addonParkingDamage,
      addonRoadsideAssist: addonRoadsideAssist,
      consentTerms: consentTerms,
      consentValidData: consentValidData,
      consentCancelCurrentInsurance: consentCancelCurrentInsurance,
      consentAsgMarketing: consentAsgMarketing,
      consentChargingStandCustomer: consentChargingStandCustomer,
      consentChargingStandConsultant: consentChargingStandConsultant,
      vehicleExistingInsuranceCompany: existingInsuranceCompanyName,
      insuranceVariant: this.selectedHelvetiaVariant.variant,
      vehicleClaims: this.selectedNumberOfDamages.value,
      vehicleExpectedYearlyMileage: this.selectedAnnualMileage.value,
      origin: 1,
      dealerId: this.selectedDealer.value,
      vehicleEngineVolume: this.vehicleFromDmr.engineVolume,
      vehicleExistingInsuranceStatus: vehicleExistingInsuranceStatus,
    };

    // Add VIN if it's not empty (backend does not accept an empty string)
    if (this.vehicleFromDmr.registrationPlate !== '') {
      applyForInsurancePayload.vehicleRegistrationNumber =
        this.vehicleFromDmr.registrationPlate;
    }

    // Set loading state and submit the insurance application
    this.applyForInsuranceLoading = true;
    this.helvetiaService
      .applyForHelvetiaInsuranceNoAuth(applyForInsurancePayload)
      .subscribe({
        next: () => {
          this.applyForInsuranceLoading = false;
          this.nextStepEvent.emit();
        },
        error: () => {
          this.applyForInsuranceLoading = false;
          this.libUtilityService.notification(
            'error',
            'Fejl',
            'Der er sket en fejl - Kontakt os på support@asgcare.dk'
          );
          return;
        },
      });
  }

  lookupExtraUserCVR() {
    if (this.helvetiaCustomerInfo.extraUserCvrNumber.trim().length == 0) {
      return;
    }
    this.extraUserCvrLoading = true;
    this.errorInExtraUserCompanyName = false;

    this.cvrService
      .lookupCVR(this.helvetiaCustomerInfo.extraUserCvrNumber, this.requireAuth)
      .subscribe({
        next: (result: any) => {
          const companyInfo = result.companyInfo;
          this.helvetiaCustomerInfo.extraUserCompanyName = companyInfo.name;
          const lookupAddress = `${companyInfo.address}, ${companyInfo.zip_code} ${companyInfo.city}`;

          this.davaService.davaAutoComplete(lookupAddress).subscribe({
            next: (result: DavaAddressInfo[]) => {
              this.helvetiaCustomerInfo.extraUserSelectedAddressInfo =
                result[0];
              this.showExtraUserAddressOption = false;
              this.recalculateHelvetiaPrices();
            },
            error: (error) => {
              this.showExtraUserAddressOption = false;
              console.error(error);
              return;
            },
          });

          this.errorInExtraUserCvrNumber = false;
          this.extraUserCvrLoading = false;
        },
        error: (error: any) => {
          this.errorInExtraUserCvrNumber = true;
          this.showExtraUserAddressOption = false;
          this.extraUserCvrLoading = false;
          console.error(error);

          return;
        },
      });
  }

  lookupCVR() {
    this.cvrLoading = true;

    this.cvrService
      .lookupCVR(this.helvetiaCustomerInfo.cvrNumber, this.requireAuth)
      .subscribe({
        next: (result: any) => {
          const companyInfo = result.companyInfo;
          this.helvetiaCustomerInfo.companyName = companyInfo.name;
          const lookupAddress = `${companyInfo.address}, ${companyInfo.zip_code} ${companyInfo.city}`;

          this.davaService.davaAutoComplete(lookupAddress).subscribe({
            next: (result: DavaAddressInfo[]) => {
              this.helvetiaCustomerInfo.selectedAddressInfo = result[0];
              this.showAddressOption = false;
            },
            error: (error) => {
              this.showAddressOption = false;
              console.error(error);
              return;
            },
          });

          this.errorInCvrNumber = false;
          this.cvrLoading = false;
        },
        error: (error: any) => {
          this.cvrLoading = false;
          this.errorInCvrNumber = true;
          console.error(error);

          return;
        },
      });
  }
}
