import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { HelvetiaPriceRequest } from 'projects/shared/src/public-api';
import { environment } from 'projects/shared/src/environments/environment';
import { ApiService } from '../api-service/api.service';

@Injectable({
  providedIn: 'root',
})
export class HelvetiaService {
  constructor(
    private router: Router,
    private apiService: ApiService,
    private loginService: LoginService
  ) {}

  /**
   * Submits a request to apply for Helvetia insurance with authentication.
   *
   * @param {any} info - The insurance application information.
   * @returns {Observable<any>} - An Observable emitting the result or an error.
   * The function handles token expiration by refreshing the token if necessary.
   * If both tokens are invalid, the user will be redirected to the login page.
   */
  applyForHelvetiaInsurance(info: any): Observable<any> {
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (!accessToken || !refreshToken) {
      console.error('Error with tokens in applyForHelvetiaInsurance');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }

    const isExpired = this.loginService.isTokenExpired(accessToken);

    if (isExpired) {
      return this.loginService.refreshAccessToken(refreshToken).pipe(
        switchMap(() => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          });
          return this.apiService.makeApiCall<any>(
            environment.apiApplyForHelvetiaInsurance,
            headers,
            'POST',
            info
          );
        }),
        catchError((error) => {
          console.error('Error refreshing token', error);
          this.loginService.clearTokens();
          this.router.navigate(['/login']);
          return throwError(() => error);
        })
      );
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });
    return this.apiService.makeApiCall<any>(
      environment.apiApplyForHelvetiaInsurance,
      headers,
      'POST',
      info
    );
  }

  /**
   * Submits a request to apply for Helvetia insurance with authentication.
   *
   * @param {any} info - The insurance application information.
   * @returns {Observable<any>} - An Observable emitting the result or an error.
   * The function handles token expiration by refreshing the token if necessary.
   * If both tokens are invalid, the user will be redirected to the login page.
   */
  applyForHelvetiaFleetPolice(info: any): Observable<any> {
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (!accessToken || !refreshToken) {
      console.error('Error with tokens in applyForHelvetiaInsurance');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }

    const isExpired = this.loginService.isTokenExpired(accessToken);

    if (isExpired) {
      return this.loginService.refreshAccessToken(refreshToken).pipe(
        switchMap(() => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          });
          return this.apiService.makeApiCall<any>(
            environment.apiApplyForHelvetiafleetPolicie,
            headers,
            'POST',
            info
          );
        }),
        catchError((error) => {
          console.error('Error refreshing token', error);
          this.loginService.clearTokens();
          this.router.navigate(['/login']);
          return throwError(() => error);
        })
      );
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });
    return this.apiService.makeApiCall<any>(
      environment.apiApplyForHelvetiafleetPolicie,
      headers,
      'POST',
      info
    );
  }

  /**
   * Submits a request to apply for Helvetia insurance without authentication.
   *
   * @param {any} info - The insurance application information.
   * @returns {Observable<any>} - An Observable emitting the result or an error.
   * This function does not require an access token.
   */

  applyForHelvetiaInsuranceNoAuth(info: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.makeApiCall<any>(
      environment.apiApplyForHelvetiaInsuranceNoAuth,
      headers,
      'POST',
      info
    );
  }

  /**
   * Creates a draft for Helvetia insurance by vehicle VIN, with optional authentication.
   *
   * @param {string} vin - The vehicle VIN number.
   * @param {boolean} [requireAuth=true] - Whether authentication is required (default is true).
   * @returns {Observable<any>} - An Observable emitting the result or an error.
   * Handles token expiration by refreshing the token if necessary.
   */
  createHelvetiaDraft(
    vin: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in createHelvetiaDraft');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            return this.apiService.makeApiCall<any>(
              `${environment.apiCreateHelvetiaDraft}${vin}`,
              headers,
              'POST'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiCreateHelvetiaDraft}${vin}`,
      headers,
      'POST'
    );
  }

  /**
   * Retrieves insurance prices for the provided Helvetia insurance information.
   *
   * @param {HelvetiaPriceRequest} insuranceInfo - The insurance request details.
   * @returns {Observable<any>} - An Observable emitting the insurance prices or an error.
   * This function does not require an access token.
   */
  getInsurancePrices(insuranceInfo: HelvetiaPriceRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.makeApiCall<any>(
      environment.apiHelvetiaPrice,
      headers,
      'POST',
      insuranceInfo
    );
  }
}
